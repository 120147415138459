require("./plugins/contentful-paint");

document.addEventListener("DOMContentLoaded", function () {
    // common
    require("./common");

    if (document.getElementById("filter-selector-wrap")) {
        import("./plugins/filter.js");
    }
    if (document.querySelector("main.template-home")) {
        import("./plugins/logo.js");
    }
    if (document.getElementById("search-popup")) {
        import("./plugins/search.js");
    }
    // if (document.querySelector('main.template-single-program-landing')) {
    //     import('./pages/single-program-landing.js');
    // }
    // if (document.querySelector('main.template-single-speakers')) {
    //     import('./pages/single-speaker.js');
    // }
    // if (document.querySelector('main.template-page-insights')) {
    //     import('./pages/insights.js');
    // }
});
